.main-header {
  height: $main-header-sm-height;
  flex-shrink: 0;

  @include media-breakpoint-up('md') {
    height: $main-header-md-height;
  }

  @include media-breakpoint-up('lg') {
    height: auto;
  }

  &__teaser {
    @extend .py-1, .py-md-2;

    background: $primary;
    color: $white;
    height: $main-header-sm-height;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $zindex-mobile-header;

    @include media-breakpoint-up('md') {
      height: $main-header-md-height;
    }

    @include media-breakpoint-up('lg') {
      position: relative;
      top: auto;
      left: auto;
    }
  }

  &__brand {
    display: block;
    max-width: 300px;
    height: 100%;
    width: 100%;
    flex-shrink: 1;

    img {
      display: block;
      max-height: 100%;
      object-position: left center;
      object-fit: contain;

      max-width: 100%;
      height: auto;
    }
  }

  &__open-mobile {
    width: 26px;
  }

  &__nav {
    background: $white;
  }

  &__collapse {
    color: $black;
    display: none;
    position: fixed;
    z-index: $zindex-mobile-navigation;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-content {
      @extend .px-2, .px-sm-4;

      display: flex;
      position: absolute;
      background: $white;
      z-index: -1;
      max-width: 80vw;
      width: 400px;
      left: 100%;
      top: 0;
      flex-direction: column;
      align-items: stretch;
      max-height: 100%;
    }

    &--visible {
      display: block;
    }

    &--open {
      background-color: fade-out($black, 0.5);

      .main-header__collapse-content {
        transform: translateX(-100%);
      }

      .main-header__toggle-mobile {
        & > * {
          width: 100%;
        }
      }
    }

    &--open-active {
      transition: background-color 0.5s ease;

      .main-header__collapse-content {
        transition: transform 0.5s ease;
      }
    }
  }

  &__close-mobile {
    height: $main-header-sm-height;
  }

  &__toggle-mobile {
    display: block;
    color: inherit;
    padding: 8px;
    margin: 0 -8px 0 0;
    border: none;
    outline: none;
    background: transparent;
    width: 38px;
    height: 38px;
    position: relative;

    & > * {
      width: 23px;
      pointer-events: none;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      left: -1px;
      height: 40px;
      width: 40px;
    }

    &[aria-expanded='false'] {
      .main-header__toggle-label--open {
        display: inline;
      }
      .main-header__toggle-label--close {
        display: none;
      }
    }
    &[aria-expanded='true'] {
      .main-header__toggle-label--open {
        display: none;
      }
      .main-header__toggle-label--close {
        display: inline;
      }
    }
  }

  &__mobile-nav {
    @extend .mx-n2, .mx-sm-n4, .px-2, .px-sm-4;

    overflow-y: auto;
    overflow-x: hidden;
  }

  &__mobile-meta {
    @extend .mx-n2, .mx-sm-n4, .px-2, .px-sm-4, .py-2, .mt-2;

    background: $primary;
    color: $white;
  }
}

// Prevent body scroll
.is-open__nav {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}
