.aside-title {
  background: $gray-100;
  height: 48px;
  border: solid $gray-200;
  border-width: 1px 0;
  color: $gray-600;
  padding: 0 $spacer * 2;
  margin-bottom: $spacer * 2;

  &__content {
    line-height: 46px;
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: $h4-font-size;
  }
}
