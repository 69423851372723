.filter-active {
  @extend .d-none, .d-lg-block;

  margin-bottom: $spacer * 3;

  &__list {
    list-style: none;
    padding-left: $spacer * 2;
    margin-bottom: $spacer * 2;

    &--submenu {
      padding-left: 0;
      margin-top: $spacer;
      margin-bottom: $spacer * 1;
    }
  }
}
