.main-search {
  @extend .mb-3;

  display: flex;
  align-items: stretch;
  height: 48px;
  position: relative;
  border: solid $teal-border;
  border-width: 1px 0;

  &__lens {
    position: absolute;
    width: 28px;
    height: 28px;
    top: 10px;
    left: 10px;
  }

  &__wrapper {
    width: 100%;
  }

  &__input {
    display: block;
    height: 100%;
    padding-left: 48px;
    padding-right: 10px;
    border: none;
    background: $white;
    width: 100%;
  }

  &__autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: $spacer 0;
    list-style: none;
    margin: 0;
    display: none;
    background: $white;
    z-index: $zindex-main-search;

    &--visible {
      display: block;
    }
  }

  &__autocomplete-item--active &__autocomplete-link {
    background: $gray-200;
  }

  &__autocomplete-link {
    padding: $spacer $spacer * 2;
    border-bottom: 1px solid $border-color;
    display: block;

    &:hover {
      background: $gray-100;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.results-highlight {
  background: #dddd00;
  padding-left: 2px;
  padding-right: 2px;
}
