.external-link__wrapper {
  border-top: 1px solid $border-color;
  padding-top: 24px;
  margin-bottom: $spacer * 3;

  .has-contact-partner & {
    border-top: none;
    padding-top: 0;
  }
  @include media-breakpoint-down('md') {
    position: fixed;
    border: 2px solid $primary;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 1001;
    padding: 0;
    margin: 0;
    height: $contact-partner-toggle-height;
    background-color: #fff;
    display: flex;
    justify-content: center;
  }
}
