.ce-table,
.table {
  .table-responsive & {
    min-width: 800px;

    @include media-breakpoint-up(lg) {
      min-width: auto;
    }
  }

  &-contact-persons {
    tr {
      td {
        &:nth-child(1) {
          max-width: 200px;
        }

        &:nth-child(3) {
          min-width: 60px;
        }
      }
    }
  }

  @extend .ce-table-contact-persons;
}
