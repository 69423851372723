.nav-meta {
  &__list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    margin: 0 $spacer * 1.5;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      color: inherit;

      &:hover {
        color: inherit;
      }
    }
  }
}
