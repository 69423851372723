.errorpage-content {
  .error-wrap {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  h1 {
    font-size: 125px;
    color: #b1b1b1;
    text-shadow: 1px 1px 2px #757575;
  }
  hr {
    width: 1px;
    color: #fff;
  }
  p.intro {
    font-size: 21px;
    line-height: 26px;
    @include media-breakpoint-up(md) {
      font-size: 28px;
      line-height: 32px;
    }
    font-weight: 700;
    font-family: 'DIN W01 Regular', sans-serif;
    text-shadow: 1px 1px 1px #fff;
    color: #4a4a4a;
  }
}
