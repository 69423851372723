.breadcrumbs {
  @extend .pb-4, .d-none, .d-md-block;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding-top: 1px;
  }

  &__item {
    color: $gray-600;
    border: solid $gray-200;
    border-width: 1px 0 1px 1px;
    margin-top: -1px;
    height: 36px;
    line-height: 34px;
    position: relative;
    padding-left: 7px;
    background: $gradient-color;

    &--home {
      padding-left: 0;
      border-left-width: 1px;

      .breadcrumbs__link {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        display: block;
      }
    }

    &:after,
    &:before {
      display: block;
      position: absolute;
      content: '';
      left: 100%;
      top: 0;
      border: solid transparent;
      margin-top: -2px;
      border-width: 19px 7px;
    }

    &:after {
      border-left-color: $gray-200;
      z-index: 100;
    }

    &:before {
      margin-left: -1px;
      border-left-color: $gradient-color;
      z-index: 200;
    }

    &--active {
      background: lighten($gradient-color, 2.5%);
      color: $gray-600;

      .breadcrumbs__link {
        pointer-events: none;
        cursor: default;
      }

      &:before {
        border-left-color: lighten($gradient-color, 2.5%);
      }
    }
  }

  &__link {
    @extend .px-2;

    height: 100%;
    display: block;
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
}
