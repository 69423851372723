.extended-footer {
  background: $gray-100;

  &__link {
    & > * {
      color: $gray-600;
    }

    &:hover {
      text-decoration: none;

      .h5 {
        text-decoration: underline;
      }
    }
  }

  &__list {
    list-style: none;
  }

  &__separator {
    border-bottom: $border-width solid $border-color;

    @include media-breakpoint-up('lg') {
      border-bottom: none;
      border-right: $border-width solid $border-color;
    }
  }

  h5 {
    a {
      @extend .text-dark;
    }
  }
}
