$supi-colors: (
  background: $body-bg,
  primary: $body-color,
  secondary: #ccc,
  button-bar: #efefef,
  success: $teal,
  warning: #ccc,
  button-wrap-bgcolor: $gray-100,
);

$supi-banner-box-border: 1px solid map-get($supi-colors, secondary) !default;
$supi-banner-box-shadow: 0 0 10px rgba(map-get($supi-colors, secondary), 0.8) !default;

@import '../../../../../../vendor/supseven/supi/Build/Src/Scss/Supi';

.tx-supi {
  --supi-color-choose: #{map-get($supi-colors, success)};

  button {
    &#supi__save {
      background: #ccc;
      color: #000;
    }

    &#supi__choose {
      color: #fff;
    }
  }

  &__button {
    margin-bottom: 24px;
  }

  &__block-item {
    margin: math.div($supi-grid-gutter-width, 4) 0;
  }
}

.frame-type-tx_supi_button {
  .tx-supi__button-wrap {
    padding-left: 0;
    padding-right: 0;
  }
}
