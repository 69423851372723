.page-link {
  &,
  &:hover {
    text-decoration: underline;
  }

  .page-item.active & {
    text-decoration: none;
  }
}

.page-item {
  &.active {
    @extend .page-link;
    color: $white;
    background-color: $pagination-color;
    border-color: $pagination-color;
    text-decoration: none;

    &:hover {
      color: $white;
      background-color: $pagination-color;
      border-color: $pagination-color;
      text-decoration: none;
    }
  }
}

.has-more-pages {
  @extend .page-link;
  text-decoration: none;

  padding-left: 0 !important;
  padding-right: 0 !important;

  @include media-breakpoint-up(md) {
    padding: 0.65rem 1rem !important;
  }

  &:hover {
    background: none;
    text-decoration: none;
  }
}

.page-link,
.page-item.active {
  padding: 0.65rem 1rem;
}

.page-next,
.page-previous {
  &__textcontent {
    @include media-breakpoint-down('sm') {
      @include sr-only();
    }
  }
}
