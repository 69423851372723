.external-link {
  &:after {
    display: inline-block;
    content: '';
    background: url('../Images/anchor-icon-external.png') no-repeat center center;
    background-size: cover;
    width: 16px;
    height: 10px;
    margin-left: 6px;
  }
}
