.lead {
  font-style: italic;
}

h1,
.h1 {
  margin-bottom: $h1-margin-bottom;
}

h2,
.h2 {
  margin-bottom: $h2-margin-bottom;
}

h3,
.h3 {
  margin-bottom: $h3-margin-bottom;
}

h4,
.h4 {
  margin-bottom: $h4-margin-bottom;
}

h5,
.h5 {
  margin-bottom: $h5-margin-bottom;
}

h6,
.h6 {
  margin-bottom: $h6-margin-bottom;
}

b,
strong {
  font-family: $font-family-sans-serif-bold;
}
