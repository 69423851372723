.download-box {
  background: $gray-100;
  margin-bottom: $paragraph-margin-bottom;

  &__header {
    background: $gradient-color;
    padding: $spacer * 2;
    text-transform: uppercase;
    color: $gray-600;
  }

  &__items {
    width: 100%;
  }
}
