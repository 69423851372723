.icon-chevron {
  display: block;
  position: relative;
  min-width: 6px;

  &--light {
    min-width: 12px;

    .icon-chevron__line {
      height: 1px;
    }
  }

  &--left {
    .icon-chevron__line {
      transform-origin: left center;
      left: 0;
    }
  }

  &--up,
  &--down {
    min-width: 12px;
    padding-top: 50%;

    .icon-chevron__line {
      width: 63.64%;

      &--down {
        transform: rotateZ(-135deg);
      }
    }
  }

  &--up .icon-chevron__line {
    top: 0;
    right: 50%;
    transform-origin: right center;
  }

  &--down .icon-chevron__line {
    transform-origin: left center;
    right: 0;
    top: 100%;
  }

  &__line {
    display: block;
    position: absolute;
    top: 50%;
    height: 2px;
    width: 127.28%;
    transform-origin: right center;
    right: 0;
    margin-top: -1px;
    background: currentColor;

    &--down {
      transform: rotateZ(45deg);
    }

    &--up {
      transform: rotateZ(-45deg);
    }
  }

  [aria-expanded] & {
    transition: all 0.2s ease-in-out;
  }

  [aria-expanded='true'] & {
    transform: rotate(180deg);
    margin-right: 7px;
  }
}
