.header-search {
  &__wrapper {
    width: 100%;
  }

  @include media-breakpoint-down('md') {
    &--mobile {
      .header-search__form {
        transition:
          background 0.3s ease,
          color 0.3s ease;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 100%;
      }

      .header-search__input {
        display: block;
        width: 100%;
        opacity: 0;
        transition: opacity 0.3s ease;
      }
    }

    &--mobile-open {
      .header-search__form {
        background: $white;
        color: $primary;
      }

      .header-search__input {
        opacity: 1;
      }
    }
  }

  @include media-breakpoint-up('lg') {
    position: relative;
  }

  &__form {
    display: flex;
    height: 38px;

    @include media-breakpoint-up('lg') {
      background: $white;
      color: $primary;
    }
  }

  &__input {
    display: none;
    margin: 0;
    padding: 0 0 0 $spacer;
    border: 0;
    outline: none;
    font: inherit;
    font-size: $font-size-sm;
    font-style: italic;
    background: transparent;

    @include media-breakpoint-up('lg') {
      display: block;
    }
  }

  &__submit {
    padding: 10px;
    margin: 0;
    border: 0;
    background: transparent;
    width: 38px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
    flex-shrink: 0;
    position: relative;

    &:before {
      content: '';
      height: 40px;
      width: 40px;
      position: absolute;
      top: -1px;
      left: -1px;
    }
  }

  &__autocomplete {
    background: $white;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    min-width: 380px;
    padding: $spacer * 2 0;
    display: none;
    z-index: $zindex-mobile-header;
    list-style: none;

    &--visible {
      display: block;
    }
  }

  &__autocomplete-item--active &__autocomplete-link {
    background: $gray-200;
  }

  &__autocomplete-link {
    padding: $spacer $spacer * 2;
    border-bottom: 1px solid $border-color;
    display: block;

    &:hover {
      background: $gray-100;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
