@font-face {
  font-family: 'DIN W01 Regular';
  font-style: normal;
  font-weight: $font-weight-base;
  src: url('../Webfonts/DinW01Regular.eot?#iefix');
  src:
    url('../Webfonts/DinW01Regular.eot?#iefix') format('eot'),
    url('../Webfonts/DinW01Regular.woff2') format('woff2'),
    url('../Webfonts/DinW01Regular.woff') format('woff'),
    url('../Webfonts/DinW01Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'DIN W01 Bold';
  src: url('../Webfonts/DinW01Bold.eot?#iefix');
  src:
    url('../Webfonts/DinW01Bold.eot?#iefix') format('eot'),
    url('../Webfonts/DinW01Bold.woff2') format('woff2'),
    url('../Webfonts/DinW01Bold.woff') format('woff'),
    url('../Webfonts/DinW01Bold.ttf') format('truetype');
  font-display: swap;
}
