.search-news-result {
  display: block;
  color: inherit;
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 3;
  border-bottom: 1px solid $border-color;

  &:first-of-type {
    border-top: 1px solid $border-color;
    margin-top: $spacer * 3;
  }

  &:last-of-type {
    margin-bottom: $spacer * 3;
  }

  &:hover {
    color: inherit;
    text-decoration: none;
    background: $gray-100;

    .search-news-result__title {
      color: $primary;
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up('md') {
    padding-left: $spacer * 2;
    padding-right: $spacer * 2;
  }
}
