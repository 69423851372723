.icon-hamburger {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 72%;

  &__salad,
  &__cheese,
  &__patty {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background: currentColor;
  }

  &__salad {
    top: 0;
  }

  &__cheese {
    top: 50%;
    margin-top: -1px;
  }

  &__patty {
    bottom: 0;
  }
}
