@use 'sass:math';

.main-teaser {
  background: $gray-100;

  &__inner {
    --grid-columns: 1;

    display: grid;
    grid-template-columns: repeat(var(--grid-columns), 1fr);

    @include media-breakpoint-up('lg') {
      --grid-columns: 2;
    }

    &-content,
    &-image {
      grid-column: 1;
      grid-row: 1;
    }

    &-content {
      z-index: 1;

      @each $bp in (sm, md) {
        @include media-breakpoint-up($bp) {
          max-width: map-get($container-max-widths, $bp);
        }
      }

      h1 {
        text-wrap: balance;

        @include media-breakpoint-only(md) {
          width: percentage(math.div(8, 12));
        }
        @include media-breakpoint-up(lg) {
          font-size: 36px;
        }
      }
    }

    &-image {
      z-index: 0;
      opacity: 0.4;

      @include media-breakpoint-up('lg') {
        grid-column: 2;
        opacity: 1;
      }
    }
  }

  & + & {
    display: none;
  }
}
