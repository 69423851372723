@use 'sass:math';

.news-slider-item {
  display: block;
  min-width: 200px;
  width: 60vw;

  @each $breakpoint in ('sm', 'md') {
    @include media-breakpoint-up($breakpoint) {
      width: math.div((map_get($container-max-widths, $breakpoint) - 160px), 3);
    }
  }

  @each $breakpoint in ('lg', 'xl') {
    @include media-breakpoint-up($breakpoint) {
      width: math.div((map-get($container-max-widths, $breakpoint) - 220px), 3);
    }
  }
}
