.teaser-box {
  p {
    @extend .h3;

    & + p {
      margin: 0;
    }
  }

  a {
    @extend .btn, .btn-outline-white;
  }
}
