.nav-footer {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: center;

    &:after {
      content: '|';
      padding: 0 $spacer;
      display: block;
    }

    &:last-child:after {
      content: unset;
    }
  }
}
