@each $color, $value in $theme-colors {
  .color-#{$color} {
    color: $value !important;
  }
}

@each $breakpoint, $b in $grid-breakpoints {
  @include media-breakpoint-up($breakpoint) {
    @each $name, $px in $spacers {
      @if $breakpoint == 'xs' {
        .gap-#{$name} {
          gap: $px;
        }
      } @else {
        .gap-#{$breakpoint}-#{$name} {
          gap: $px;
        }
      }
    }
  }
}

.text-wrap-balance {
  text-wrap: balance !important;
}
