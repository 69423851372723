.textpic {
  &-intext {
    @include clearfix;

    img {
      &.img-fluid {
        width: 100%;
      }
    }

    ul {
      overflow: hidden;
    }

    @include media-breakpoint-up(md) {
      figure {
        max-width: 30%;

        &.d-md-table {
          figcaption {
            display: table-caption;
            caption-side: bottom;
          }
        }
      }
    }
  }
}
