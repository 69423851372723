.filter-available {
  @extend .d-none, .d-lg-block;

  &__title {
    font-family: $font-family-sans-serif-bold;
    font-size: 1rem;
    font-weight: bolder;
    line-height: 1.5;
  }

  &__list {
    list-style: none;
    padding-left: $spacer * 2;
    margin-bottom: $spacer * 2;
    margin-top: $spacer;

    &--with-ids {
      padding-left: 0;
    }

    &--submenu {
      .filter-available__item {
        display: flex;
      }

      &-submenu {
        margin-left: map-get($spacers, 2);
        font-size: 90%;
        margin-top: 0;
        opacity: 0.75;

        .filter-available__id {
          min-width: 0;
          margin-right: 6px;
          line-height: 1.8;
        }

        .filter-available__level-2 {
          display: none;
        }
      }
    }
  }

  &__id {
    @extend .small, .text-muted;

    display: block;
    flex-shrink: 0;
    min-width: 35px;
    line-height: $font-size-base * $line-height-base;
    align-self: flex-start;
  }

  &__link {
    &,
    &:hover {
      color: inherit;
    }
  }
}
