.filter-mobile {
  @extend .d-lg-none;

  &__filter {
    @extend .mb-3;

    display: flex;
    border: solid $border-color;
    border-width: 1px 0;
    position: relative;
  }
}
