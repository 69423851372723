.news {
  &-item,
  &-slider-item {
    position: relative;

    h4,
    h3 {
      text-wrap: balance;

      a {
        color: $black;

        &:hover {
          text-decoration: none;
        }

        &:before {
          content: '';
          position: absolute;
          inset: 0;
          z-index: 4;
        }
      }
    }
  }

  &-item {
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 24px;
    padding-top: 24px;

    &__meta {
      font-size: 13px;
    }

    &__description {
      position: relative;
      z-index: 2;
    }

    [class^='col-'] {
      position: static;
    }

    &__readmore {
      z-index: 0;
    }
  }

  &-slider,
  &-item {
    &__description,
    &__date {
      z-index: 3;
      position: relative;
    }
  }
}

.frame-type-news_newsliststicky,
.frame-type-news_newsdetail {
  &:has(+ .frame-type-theme_quicklinks) hr,
  &:has(+ .frame-type-theme_quicklinks) hr {
    display: none;
  }
}
