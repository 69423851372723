.collapsible {
  &__heading {
    button {
      font-size: 1rem;
      font-family: $font-family-sans-serif-bold;
      gap: $grid-gutter-width * 0.5;

      &.bg-set {
        background-color: map-get($theme-colors, 'primary');
        color: map-get($theme-colors, 'white');

        &:hover,
        &:focus {
          background-color: $teal-slightly-darker;
        }

        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(2%) hue-rotate(284deg) brightness(107%)
            contrast(100%);
        }
      }

      img {
        margin: -5px ($grid-gutter-width * 0.5) 0 0;
        max-height: 24px;
        width: auto;
      }

      .icon-chevron {
        flex: 0 0 auto;

        &--down {
          padding: 0;
          width: 20px;
          height: 10px;
          color: map-get($theme-colors, 'primary');
        }
      }
    }
  }
}
