.nav-aside {
  @extend .d-none, .d-lg-block;

  &__list {
    list-style: none;
    margin-bottom: $spacer;
    padding-left: $spacer * 3;

    &--first-level {
      margin: 0;

      &,
      & > .nav-aside__item > .nav-aside__list {
        padding: 0;
      }

      & > .nav-aside__item {
        &,
        & > .nav-aside__list {
          border-top: 1px solid $gray-200;
        }

        & > .nav-aside__list {
          padding-top: $spacer * 1.5;
        }

        &:last-child {
          border-bottom: 1px solid $gray-200;
        }

        & > .nav-aside__link {
          padding-top: 7.5px;
          padding-bottom: 7.5px;
          font-size: $font-size-lg;
          display: flex;
          align-items: baseline;
        }

        &--active {
          & > .nav-aside__link {
            font-weight: $font-weight-base;

            &,
            &:hover {
              color: $primary;
            }
          }

          &.nav-aside__item--has-submenu {
            .nav-aside__arrow {
              transform: translateY(-50%) rotateZ(90deg);
            }
          }
        }
      }
    }

    .nav-aside__list {
      display: none;
    }
  }

  &__item {
    &--active > .nav-aside__link {
      font-weight: $font-weight-bold;
    }

    &--active {
      & > .nav-aside__list {
        display: block;
      }
    }
  }

  &__link {
    padding: 0 $spacer * 2;
    display: block;
    position: relative;

    &,
    &:hover {
      color: inherit;
    }
  }

  &__arrow {
    display: block;
    position: absolute;
    right: $spacer * 2;
    top: 50%;
    transform: translateY(-50%);
    color: $gray-300;
  }
}
