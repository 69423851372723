@use 'sass:math';

.news-slider {
  padding: $spacer * 3 0;

  &__nav {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__nav-item {
    margin-left: $spacer * 2;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    @include media-breakpoint-up('lg') {
      padding: 0 $grid-gutter-width * 0.5;
    }

    @each $breakpoint in ('lg', 'xl') {
      @include media-breakpoint-up($breakpoint) {
        max-width: map-get($container-max-widths, $breakpoint);
      }
    }
  }

  &__buttons {
    position: absolute;
    display: flex;
    top: 50%;
    left: 0;
    justify-content: space-between;
    transform: translateY(-50%);
    width: 100%;
  }

  &__arrow {
    padding: 0;
    display: none;
    border: 0;
    margin: 0;
    background: transparent;
    flex-shrink: 0;
    flex-grow: 0;
    width: 30px;
    cursor: pointer;
    height: 60px;
    color: $gray-300;

    @include media-breakpoint-up('lg') {
      display: block;
    }
  }

  &__slider {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    position: relative;

    @include media-breakpoint-up('lg') {
      margin: 0 $grid-gutter-width * 0.5;
      overflow-x: hidden;
      scroll-snap-type: none;
    }
  }

  &__content {
    display: flex;
    flex-shrink: 0;
  }

  &__container-spacer {
    width: 0;
    flex-shrink: 0;
    flex-grow: 0;
    height: 1px;
    background: transparent;

    @each $breakpoint, $value in $container-max-widths {
      @include media-breakpoint-up($breakpoint) {
        width: calc((100% - #{$value}) * 0.5);
      }
    }

    @include media-breakpoint-up('lg') {
      display: none;
    }

    &:first-child {
      scroll-snap-align: start;
    }

    &:last-child {
      scroll-snap-align: end;
    }
  }

  &__item {
    flex-shrink: 0;
    scroll-snap-align: center;
    scroll-snap-stop: always;
    margin: 0 $grid-gutter-width * 0.5;

    @include media-breakpoint-up('lg') {
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.is-active {
        position: relative;
        &:after {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          border: 3px solid $primary;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__cards {
      .news-slider {
        &__wrapper {
          @each $breakpoint in ('md', 'lg', 'xl') {
            @include media-breakpoint-up($breakpoint) {
              padding: 0 $grid-gutter-width * 0.5;
              max-width: map-get($container-max-widths, $breakpoint);
            }
          }
        }

        &__buttons,
        &__container-spacer {
          display: none;
        }

        &__slider {
          margin: 0;
          overflow-x: initial;
        }

        &__content {
          flex-wrap: wrap;
          flex: 0 auto;
          gap: $grid-gutter-width;
        }

        &__item {
          margin: 0;
          flex: 1 0 calc(#{percentage(math.div(4, 12))} - #{$grid-gutter-width});

          &.is-active:after {
            display: none;
          }
        }

        &-item {
          width: auto;
        }
      }

      picture {
        display: block;
        margin-bottom: $spacer * 2;
      }
    }
  }
}
