.d-grid {
  display: grid;
}

@each $breakpoint, $b in $grid-breakpoints {
  @include media-breakpoint-up($breakpoint) {
    .d-#{$breakpoint}-grid {
      display: grid;
    }
  }
}
