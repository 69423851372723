.contact-partner {
  border-top: 1px solid $border-color;
  padding-top: $spacer * 3;
  margin-bottom: $spacer * 3;

  .container > div {
    .contact-partner__content {
      margin-bottom: $spacer * 3;
      display: flex;
      flex-direction: column;
    }

    &:last-child .contact-partner__content {
      @include media-breakpoint-up('lg') {
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-down('md') {
    position: fixed;
    top: 100%;
    margin-top: -45px;
    padding-top: 0;
    left: 0;
    width: 100%;
    background: $gray-100;
    border-top: none;
    transition: all 0.2s ease-in-out;

    .has-external-link & {
      margin-top: -90px;
    }

    &--toggle {
      margin-top: 0;

      .contact-partner__mobile-toggle {
        background: inherit;
        color: $primary;
      }

      .contact-partner__chevron {
        transform: rotateZ(180deg);
      }

      &-animating {
        transition:
          margin-top 0.3s linear,
          transform 0.3s linear;

        .contact-partner__mobile-toggle {
          transition:
            background-color 0.3s linear,
            color 0.3s linear;
        }

        .contact-partner__chevron {
          transition: transform 0.3s linear;
        }
      }

      &-active {
        &:before {
          content: '';
          display: block;
          border-top: 1px solid $border-color;
        }
      }
    }

    &__mobile-toggle {
      height: $contact-partner-toggle-height;
      background: $primary;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      padding-bottom: 4px;
      cursor: pointer;
    }

    .contact-partner--toggle-active & {
      margin: 0;
      transform: translateY(-100%);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

      .has-external-link & {
        margin-top: -45px;
      }
    }
  }

  @include media-breakpoint-up('lg') {
    &--sticky {
      position: sticky;
      top: 0 !important;
      border-top: none;
      margin-bottom: 0;
    }
  }

  &__image {
    @extend .img-fluid;

    max-width: 75px;
    display: block;
  }

  &__mobile-toggle {
    @extend .d-lg-none;
  }

  &__chevron {
    display: block;
    width: 12px;
  }

  &__wrapper {
    @extend .container;

    @include media-breakpoint-up('lg') {
      padding-left: 0;
      padding-right: 0;
      max-width: unset;
    }
  }
}

.list-type-ttaddress_listview {
  top: -200px;
  transition: top 0.2s ease-in-out;
}
