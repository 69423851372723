.frame-type-theme_browsable_pdf {
  .theme_browsable_pdf--wrapper {
    & > div {
      display: grid;
      grid-auto-columns: auto;
      grid-auto-rows: auto auto;

      & > div,
      & > figure {
        grid-column: 1 / 1;
        grid-row: 1 / 2;
      }

      & > figure,
      .image-placeholder {
        background: $light;
        z-index: 0;
        grid-row: 2 / 2;

        @include media-breakpoint-up(lg) {
          grid-row: 1 / 2;
        }
      }

      & > div {
        z-index: 1;

        div {
          background: rgba(#fff, 0.9);
        }
      }
    }
  }
}
