.tx-felogin-pi1,
.frame-type-felogin_login {
  .status {
    color: $gray-300;
    font-family: $font-family-sans-serif;
    font-weight: normal;
    font-size: 1rem;
    margin: 0;
  }

  input {
    &[type='text'],
    &[type='password'] {
      background: #fff;
      border: 1px solid #c8ccd0;
      width: 100%;
      padding: 8px 9px;
      font-size: 0.85rem;
      color: $black;

      &::placeholder {
        color: $gray-200;
        font-style: italic;
      }
    }
  }
}
