.download-item {
  position: relative;
  display: flex;
  align-items: stretch;
  border-top: 1px solid $border-color;

  &__image {
    width: 60px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $border-color;

    &:hover + .download-item__content .download-item__filename {
      text-decoration: underline;
    }
  }

  &__preview {
    padding: $grid-gutter-width * 0.5;
    flex: 0 0 191px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $border-color;
  }

  &__content {
    padding: $spacer * 2;
    font-size: 1rem;
  }

  &__filename {
    color: $primary;
  }
}
