.icon-lens {
  display: block;
  width: 100%;
  padding-top: 100%;
  position: relative;

  &__circle,
  &__line {
    display: block;
    position: absolute;
  }

  &__circle {
    width: 75%;
    height: 75%;
    border-radius: 50%;
    border: 2px solid currentColor;
    top: 0;
    left: 0;
  }

  &__line {
    width: 50%;
    height: 2px;
    right: 0;
    bottom: 0;
    background: currentColor;
    transform-origin: right center;
    transform: rotateZ(45deg);
  }
}
