.content-footer {
  @extend .px-2, .py-3, .mt-5;

  display: flex;
  justify-content: flex-end;

  @include media-breakpoint-up(md) {
    justify-content: space-between;
  }

  align-items: center;
  background: $gray-100;
  border: solid $teal-border;
  border-width: 1px 0;

  &__link-button {
    background: transparent;
    border: 0;
    color: $teal;

    &:hover {
      text-decoration: underline;
    }
  }
}
