.nav-main {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;

  &__link {
    color: inherit;

    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }

  &__submenu {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &--mobile {
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 56px;

    .nav-main__item {
      border-top: 1px solid $gray-300;
      padding: 1px 0;

      &:last-child {
        border-bottom: 1px solid $gray-300;
      }

      &--open > .nav-main__content {
        .nav-main__submenu-toggle .nav-main__arrow {
          transform: rotateZ(90deg);
        }
      }
    }

    .nav-main__content {
      display: flex;
      align-items: center;
    }

    .nav-main__link {
      padding-top: $spacer * 2;
      padding-bottom: $spacer * 2;
      width: 100%;
      font-weight: $font-weight-bold;
    }

    .nav-main__submenu-toggle {
      cursor: pointer;
      flex-shrink: 0;
    }

    .nav-main__arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary;
      transform-origin: center center;
      transition: transform 0.5s ease;
      background: transparent;
      height: 40px;
      width: 40px;
      border: 0;
      margin: 0 -9px 0 0;
      padding: 0;
    }

    .nav-main__submenu {
      overflow: hidden;
      display: none;
      transition: max-height 0.5s ease;

      .nav-main__item {
        color: $gray-600;

        &:last-child {
          border-bottom: none;
        }
      }

      .nav-main__link {
        padding-top: $spacer * 1.5;
        padding-bottom: $spacer * 1.5;
        color: inherit;
        font-size: $font-size-sm;
        font-weight: $font-weight-base;
      }

      .nav-main__arrow {
        color: inherit;
      }

      .nav-main__submenu {
        border-top: 1px solid $gray-300;

        .nav-main__item {
          border-top: none;
          padding: 0;

          &:first-child {
            margin-top: $spacer;
          }

          &:last-child {
            margin-bottom: $spacer;
          }
        }

        .nav-main__link {
          @extend .pl-2;

          color: $primary;
          padding-top: $spacer * 0.5;
          padding-bottom: $spacer * 0.5;

          &:hover {
            color: $teal-darker;
          }
        }
      }
    }
  }

  &--desktop {
    @extend .mx-n2;

    .nav-main {
      &__item {
        &:focus-within {
          color: $primary;
        }

        &:hover {
          color: $primary;
        }

        &--has-submenu {
          &:focus-within {
            .nav-main__submenu {
              display: block;
            }
          }

          &:hover {
            .nav-main__submenu {
              display: block;
            }
          }
        }

        &--is-subitem {
          border-bottom: 1px solid $gray-300;
          padding: 1px 0;

          &:focus-within {
            .nav-main__link {
              color: $white;
              background: $primary;
            }
          }

          &:hover {
            .nav-main__link {
              color: $white;
              background: $primary;
            }
          }

          .nav-main__link {
            @extend .py-1, .px-2;

            &--active {
              color: $white;
              background: $primary;
            }
          }
        }
      }

      &__link {
        @extend .py-2, .px-2;

        display: block;

        &--active {
          color: $primary;
        }
      }

      &__submenu {
        width: 300px;
        display: none;
        position: absolute;
        z-index: $zindex-mobile-navigation;
        background: $white;
        padding: 5px 12px 15px;
        color: $body-color;
      }
    }
  }
}
