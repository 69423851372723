.cookie-consent {
  display: block;
  position: fixed;
  background: $gray-300;
  color: $white;
  width: 100%;
  padding: $spacer * 3;
  bottom: 0;
  left: 0;
  z-index: $zindex-cookies;

  &__content {
    font-size: $font-size-sm;

    a {
      text-decoration: underline;

      &,
      &:hover {
        color: inherit;
      }
    }
  }

  &__decline {
    text-decoration: underline;
  }

  @include media-breakpoint-up('md') {
    bottom: $spacer * 3;
    left: $spacer * 3;
    max-width: 400px;
  }
}
