.search-button {
  @extend .px-2;

  display: flex;
  align-items: center;
  flex-shrink: 0;
  border: none;
  background: darken($gray-100, 3);
  color: $gray-600;
}
