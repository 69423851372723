.icon-close {
  display: block;
  width: 100%;
  padding: 100% 0 0;
  position: relative;

  &__line {
    width: 141.421%;
    height: 2px;
    background: currentColor;
    position: absolute;
    left: 0;
    transform-origin: left center;

    &--down {
      top: -1px;
      transform: rotateZ(45deg);
    }

    &--up {
      bottom: -1px;
      transform: rotateZ(-45deg);
    }
  }
}
