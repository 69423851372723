body:not(.rootpage) {
  main {
    #content-area {
      > :not(aside) {
        a {
          text-decoration: underline;
        }
      }
    }
  }
}
