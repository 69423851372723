.filter-mobile-item {
  border-right: 1px solid $border-color;
  margin: $spacer 0;
  padding: 0;
  min-width: 135px;
  padding-right: $spacer;
  margin-right: $spacer;

  @include media-breakpoint-up('sm') {
    padding-right: $spacer * 2;
    margin-right: $spacer * 2;
    min-width: 150px;
  }

  @include media-breakpoint-up('md') {
    padding-right: $spacer * 3;
    margin-right: $spacer * 3;
    min-width: 170px;
  }

  &:last-child {
    border-right: none;
    padding-right: 0;
    margin-right: 0;
  }

  &__select {
    height: 100%;
    color: inherit;
    line-height: 35px;
    list-style: none;
    background: transparent;
    width: 100%;
    appearance: none;
    border: none;
    cursor: pointer;
    outline: none;
    margin: 0 (-$spacer);
    padding: 0 $spacer;

    option {
      color: $body-color;
    }

    &:focus {
      background: $white;
    }
  }

  &__dropdown {
    color: $primary;
    position: relative;
    height: 35px;
    border-radius: 0;
    background: $white;
    padding: 0 ($grid-gutter-width * 0.25);

    &:after {
      content: '';
      position: absolute;
      right: $grid-gutter-width * 0.25;
      top: 50%;
      margin-top: -7px;
      width: 9px;
      height: 9px;
      border-top: 2px solid $primary;
      border-right: 2px solid $primary;
      transform: rotate(135deg);
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    pointer-events: none;

    &--hidden {
      display: none;
    }
  }

  &__chevron {
    margin-left: $spacer;
    width: 12px;
  }

  &__link {
    &,
    &:hover {
      color: inherit;
    }
  }

  &__active-filter {
    margin: $spacer 0;
  }

  &__id {
    @extend .small, .text-muted;

    display: block;
    flex-shrink: 0;
    min-width: 55px;
    line-height: $font-size-base * $line-height-base;
    padding-top: 2px;
  }
}
