@media print {
  body {
    margin-bottom: 50px;

    main,
    footer {
      a {
        color: $black;
      }
    }

    &.has-solr-filter {
      main .breadcrumbs + header {
        display: none;
      }
    }
  }

  .layout-background {
    background: none;
  }

  .teaser-box,
  .news-slider__arrow,
  .news-slider__container-spacer,
  #TSFE_ADMIN_PANEL_FORM,
  .main-teaser {
    @extend .d-print-none;
  }

  .news-slider__slider,
  .news-slider__content,
  .news-slider__item {
    display: block;
    width: 100%;
    margin: 0;
  }

  .news-slider__content {
    margin-left: 50px;
  }

  .news-slider__item {
    margin-bottom: $grid-gutter-width;
    page-break-inside: avoid;

    article {
      width: 100%;

      &:after {
        content: '';
        display: table;
        clear: both;
      }

      header {
        float: left;
        margin-right: $grid-gutter-width;
        max-width: 320px;
      }
    }
  }

  .print-footer {
    @extend .d-print-block;

    &:before,
    &:after {
      position: fixed;
      left: 0;
      width: 100%;
      font-size: 12px;
    }

    &:after {
      bottom: 0;
      content: attr(data-title) ' / ' attr(data-url);
      border-top: 1px solid grey;
    }
  }

  .frame {
    page-break-inside: avoid;

    &:last-of-type {
      page-break-after: auto;
      page-break-inside: auto;
    }
  }

  section + aside,
  article + aside {
    page-break-before: always;
  }

  .contact-partner {
    position: relative;
    margin-top: auto;

    .container {
      padding: 0;
    }
  }

  .page-break-inside-avoid {
    page-break-inside: avoid;
  }

  .table {
    &.table-borderless {
      width: 100% !important;
      border-top: 1px solid $gray-600;
      border-left: 1px solid $gray-600;

      th,
      td {
        border-bottom: 1px solid $gray-600;
        border-right: 1px solid $gray-600;
      }
    }
  }

  mark {
    font-family: $font-family-sans-serif-bold;
    padding: 0;
    background: none;
  }

  .frame-type-products_show {
    table {
      margin-bottom: $grid-gutter-width;
    }

    .list-unstyled {
      li {
        display: inline;

        a {
          font-size: 80%;
          text-decoration: none;

          &:after {
            content: '; ';
            margin-left: -3px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .news-item {
    &.article {
      page-break-inside: avoid;
    }
  }

  .list-type-ttaddress_listview {
    & > div {
      page-break-inside: avoid;
    }
  }

  .solr-list-item {
    border: 1px solid $hr-border-color;
    border-bottom: none;
    display: table;
    width: 100%;
    margin-bottom: 0 !important;

    &:last-of-type {
      border-bottom: 1px solid $hr-border-color;
    }

    h4,
    h4 + div {
      display: table-cell;
      width: 50%;
      font-size: 1rem;
      line-height: 1.4;
      padding: $grid-gutter-width * 0.25;

      & > div {
        max-width: none;
      }
    }

    h4 + div {
      border-left: 1px solid $hr-border-color;
    }

    hr {
      display: none !important;
    }
  }
}
