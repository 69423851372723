.filter-active-item {
  display: flex;
  align-items: center;

  &,
  &:hover {
    color: inherit;
  }

  &__close {
    color: $primary;
    border: 1px solid $black;
    width: 1rem;
    height: 1rem;
    padding: 2px;
    display: block;
    margin-right: $spacer;
    flex-shrink: 0;
    align-self: flex-start;
    margin-top: 5px;
  }
}
