.contenttable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  thead {
    tr {
      font-family: 'DIN W01 Bold';
      background: $gradient-color;
      text-align: left;
      border-top: 1px solid #d3dbe1;

      &:last-child {
        border-right: 0px;
      }

      &:first-child {
        border-top: 0px;
      }

      th {
        white-space: nowrap;
        padding: $spacer * 2;
        border-right: 1px solid #d3dbe1;
        text-align: left;

        &:last-child {
          border-right: 0px;
        }

        @include media-breakpoint-up(lg) {
          white-space: normal;
        }
      }
    }
  }

  tbody {
    tr {
      background: $gray-100;
      text-transform: uppercase;
      border-top: 1px solid #d3dbe1;

      &:first-child {
        border-top: 0px;
      }

      th {
        white-space: nowrap;
        font-family: 'DIN W01 Bold';
        background: $gradient-color;
        padding: $spacer * 2;
        border-right: 1px solid #d3dbe1;
        text-align: left;

        &:last-child {
          border-right: 0px;
        }

        @include media-breakpoint-up(lg) {
          white-space: normal;
        }
      }

      td {
        white-space: nowrap;
        padding: $spacer * 2;
        border-top: 1px solid #d3dbe1;
        text-align: left;
        border-right: 1px solid #d3dbe1;
        border-top: 0px;

        @include media-breakpoint-up(lg) {
          white-space: normal;
        }

        &:last-child {
          border-right: 0px;
        }
      }
    }
  }

  & th:first-child[scope='row'] {
    left: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    background: $gradient-color;
    border-right: 1px solid #d3dbe1;
  }
}

@include media-breakpoint-down(lg) {
  .scroll-table {
    position: relative;
    width: 100%;
    overflow-y: scroll;
    margin: 0 0 1em;
  }
}
