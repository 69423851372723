//*******************************************
// BEFORE INCLUDING BOOTSTRAP
//
// 1. Color System
// 2. Spacing
// 3. Grid
// 4. General Settings
// 5. Typography
// 6. Buttons
// 7. Pagination
//*******************************************

// COLOR SYSTEM
//*******************************************
$black: #000;
$gray-600: #6c6c6e;
$gray-300: #bdc5cb;
$gray-200: #d3dbe1;
$gray-100: #fafbfc;
$white: #fff;

$teal: #00807e;
$green: #008a41;
$red: #8a1a00;
$orange: #998226;
$blue: #267599;

$primary: $teal;
$success: $green;
$danger: $red;
$warning: $orange;
$info: $blue;
$light: $gray-300;
$dark: $gray-600;

$theme-colors: (
  'white': $white,
  'black': $black,
  'light': $gray-100,
);

$teal-darker: #015756;
$teal-dark: #016a68;
$teal-slightly-darker: #006f6d;
$teal-light: #02acaa;
$teal-lighter: #02c0bd;

$teal-border: #d3dbe1;

$gradient-color: #eef1f3;

$body-bg: $white;
$body-color: $black;

$border-color: $gray-200;

$link-hover-color: $teal-dark;

// SPACING
//*******************************************
// GRID SETTINGS
//*******************************************
$grid-gutter-width: 40px;

$spacer: 8px;
$spacers: (
  0: 0,
  1: (
    $spacer * 1,
  ),
  2: (
    $spacer * 2,
  ),
  3: (
    $spacer * 3,
  ),
  4: (
    $spacer * 4,
  ),
  5: (
    $spacer * 5,
  ),
  sg: $grid-gutter-width * 0.2,
  hg: $grid-gutter-width * 0.5,
  fg: $grid-gutter-width,
  1h: $grid-gutter-width * 1.5,
  dg: $grid-gutter-width * 2,
);

// GENERAL SETTINGS
//*******************************************
$border-radius: 0;
$border-radius-sm: $border-radius;
$border-radius-lg: $border-radius;

// TYPOGRAPHY
//*******************************************
$font-family-sans-serif:
  'DIN W01 Regular',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  Arial,
  'Noto Sans',
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji';
$font-family-sans-serif-bold:
  'DIN W01 Bold',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  Arial,
  'Noto Sans',
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji';

$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.875;

$headings-font-weight: 600;
$headings-margin-bottom: $font-size-base;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 1.8;
$h3-font-size: $font-size-base * 1.625;
$h4-font-size: $font-size-base * 1.375;
$h5-font-size: $font-size-base * 1.1875;
$h6-font-size: $font-size-base * 1.0625;

$h1-line-height: 1.2;
$h2-line-height: 1.25;
$h3-line-height: 1.31;
$h4-line-height: 1.36;
$h5-line-height: 1.47;
$h6-line-height: 1.53;

$h1-margin-bottom: $headings-margin-bottom * 1.875;
$h2-margin-bottom: $headings-margin-bottom * 1.65;
$h3-margin-bottom: $headings-margin-bottom * 1.4;
$h4-margin-bottom: $headings-margin-bottom * 1.15;
$h5-margin-bottom: $headings-margin-bottom;
$h6-margin-bottom: $headings-margin-bottom;

$hr-margin-y: $spacer * 3;

$mark-padding: 0.2em 0;

// BUTTONS
//*******************************************
$input-btn-font-size: $font-size-base * 1.125;
$input-btn-focus-width: 0;
$input-btn-focus-color: transparent;

// PAGINATION
//*******************************************
$pagination-bg: transparent;
$pagination-border-width: 0;
$pagination-border-color: transparent;
$pagination-hover-bg: $gray-200;

// TABLES
//*******************************************
$table-cell-padding: map-get($spacers, 1);
$table-cell-padding-sm: map-get($spacers, 1);

//*******************************************
// INCLUDE BOOTSTRAP VARIABLES
//*******************************************
@import '../../../node_modules/bootstrap/scss/variables';
