.youtube {
  position: relative;
  max-width: 854px;
  max-height: 480px;
  overflow: hidden;

  &--hidden {
    .youtube__overlay {
      display: block;
    }

    .youtube__outlet {
      display: none;
    }
  }

  &__content {
    width: 100%;
    height: 0;
    padding-top: 56.206%;
    position: relative;
  }

  &__teaser {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: blur(5px);
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    display: none;

    &-content {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: calc(100% - #{$spacer * 4});
      max-height: calc(100% - #{$spacer * 4});
      transform: translate3d(-50%, -50%, 0);
      background: $white;
      text-align: center;
      padding: $spacer * 3;
      width: 400px;
    }
  }

  &__outlet {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
