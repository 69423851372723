.frame-type-menu_section {
  h2 {
    font-size: 22px;
    margin-bottom: $grid-gutter-width * 0.5;
  }

  ul {
    padding: 0;

    li {
      padding-block: 4px;
      margin-left: 24px;

      a {
        line-height: 1.5;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
