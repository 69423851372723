@use 'sass:math';

.quicklinks {
  &__item {
    flex: 1 0 100%;

    @include media-breakpoint-up(md) {
      flex: 1 0 calc(#{percentage(math.div(4, 12))} - #{$grid-gutter-width});
    }
  }
}
