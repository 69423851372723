.collapsible-element {
  position: relative;

  a {
    pointer-events: none;
    position: relative;
    cursor: pointer;
    padding-left: 21px;
  }

  .icon-chevron {
    width: 7px;
    position: absolute;
    top: -32px;
    left: 0px;
  }

  .toggle {
    &-button {
      width: 100px;
      height: 25px;
      position: absolute;
      top: 0;
      left: 0;
      appearance: none;
      cursor: pointer;
      opacity: 0;
      z-index: 1;

      &:focus {
        outline: none;
      }

      & + a + .toggle-element {
        display: none;
      }

      &:checked {
        display: none;

        & + a {
          display: none;

          & + .toggle-element {
            display: block;
          }
        }
      }
    }
  }
}
