.dev {
  display: none;

  body.develop & {
    display: block;
  }

  position: fixed;
  bottom: 0;
  left: 0;
  background: #000;
  color: #fff;
  font-size: 9px;
  font-style: normal;
  padding: 5px;
  z-index: 9999;
}
