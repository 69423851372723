html,
body {
  min-height: 100%;
  height: 100%;
}

html {
  overflow-x: hidden;
  word-break: break-word;
  hyphens: auto;
  scroll-behavior: smooth;

  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}

body {
  &.has-contact-partner {
    @include media-breakpoint-down('md') {
      padding-bottom: $contact-partner-toggle-height;
    }
  }
}
