:focus-visible {
  outline: 3px solid $black;
}

.nav-main--desktop {
  .nav-main__link {
    &:focus-visible {
      outline-offset: -5px;
    }
  }

  .nav-main__submenu {
    .nav-main__link {
      &:focus-visible {
        outline-offset: 0;
      }
    }
  }
}

.nav-main--mobile {
  .nav-main__link {
    &:focus-visible {
      outline-offset: -2px;
    }

    .nav-main__submenu & {
      &:focus-visible {
        outline-offset: 0;
      }
    }
  }
}

.breadcrumbs__link {
  &:focus-visible {
    outline-offset: -5px;
  }
}

.btn:focus-visible,
.teaser-box a:focus-visible {
  outline: 3px solid $black;
}

//  This will hide the focus indicator if the element receives focus via the mouse,
//  but it will still show up on keyboard focus.

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.js-focus-visible .focus-visible {
  outline: 3px solid $black;
}

.js-focus-visible .nav-main__link {
  .focus-visible {
    outline-offset: -5px;
  }
}

.js-focus-visible {
  .breadcrumbs__link {
    &.focus-visible {
      outline-offset: -5px;
    }
  }
}

.js-focus-visible {
  .nav-main--mobile {
    .nav-main__submenu {
      .nav-main__link {
        &.focus-visible {
          outline-offset: -2px;
        }
      }
    }
  }

  .nav-main--desktop {
    .nav-main__link {
      &.focus-visible {
        outline-offset: -5px;
      }
    }
  }

  .nav-main__submenu {
    .nav-main__link {
      &.focus-visible {
        outline-offset: 0px;
      }
    }
  }
}

.js-focus-visible .btn.focus-visible,
.js-focus-visible .teaser-box a.focus-visible {
  outline: 3px solid $black;
}
